import { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as RadixTabs from '@radix-ui/react-tabs';
import TabsContext from '@/storychief/components/Tabs/TabsContext';

const propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
const defaultProps = {};

function TabsListItem({ value, children }) {
  const { currentValue } = useContext(TabsContext);

  return (
    <li className={classNames({ active: value === currentValue })}>
      <RadixTabs.Trigger value={value}>{children}</RadixTabs.Trigger>
    </li>
  );
}

TabsListItem.propTypes = propTypes;
TabsListItem.defaultProps = defaultProps;

export default TabsListItem;
